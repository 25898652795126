// assets
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// constant
const icons = {
    VideogameAssetIcon,
    SportsEsportsIcon,
    AccountCircleIcon
};

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const Game = {
    id: 'games',
    title: 'Games',
    type: 'group',
    children: [
        {
            id: 'games',
            title: 'Games',
            type: 'item',
            url: '/games',
            icon: icons.SportsEsportsIcon,
            breadcrumbs: false
        }
    ]
};

export default Game;
