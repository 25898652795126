/* eslint-disable no-else-return */
import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

// ===========================|| ROUTING RENDER ||=========================== //

export default function ThemeRoutes() {
    const AuthRoutes = useRoutes([AuthenticationRoutes]);
    const AppRoutes = useRoutes([MainRoutes]);
    const token = localStorage.getItem('access_token');
    if (token) {
        return AppRoutes;
    } else {
        return AuthRoutes;
    }
}
