// assets
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// constant
const icons = {
    VideogameAssetIcon,
    SportsEsportsIcon,
    AccountCircleIcon
};

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const Pods = {
    id: 'pods',
    title: 'Pods',
    type: 'group',
    children: [
        {
            id: 'pods',
            title: 'Pods',
            type: 'item',
            url: '/pods',
            icon: icons.SportsEsportsIcon,
            breadcrumbs: false
        }
    ]
};

export default Pods;
