import React from 'react';

// material-ui
import { useTheme } from '@material-ui/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from './../../assets/images/logo-dark.svg';
 * import logo from './../../assets/images/logo.svg';
 *
 */

// ===========================|| LOGO SVG ||=========================== //

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */
        <div className="logo">
            {/* <img src="/escapely/Escapely_big@3x.png" alt="Logo" style={{ width: '100%' }} /> */}
            <h1 style={{ fontFamily: 'monospace', color: '#fff' }}>escapely</h1>
        </div>
    );
};

export default Logo;
