// assets
import { IconSettings } from '@tabler/icons';

// constant
const icons = {
    IconSettings
};

// ===========================|| DASHBOARD MENU ITEMS ||=========================== //

const settings = {
    id: 'settings',
    title: 'Settings',
    type: 'group',
    children: [
        {
            id: 'settings',
            title: 'Settings',
            type: 'item',
            url: '/settings',
            icon: icons.IconSettings,
            breadcrumbs: false
        }
    ]
};

export default settings;
