import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import HistoryDetails from 'views/Games/historyDetails';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// Game dashboard
const GameDashboard = Loadable(lazy(() => import('views/Games/index')));
const GameDetails = Loadable(lazy(() => import('views/Games/details')));
const GamesHistory = Loadable(lazy(() => import('views/Games/history')));
const Scenedetails = Loadable(lazy(() => import('views/Games/scenedetails')));
const UsersIndex = Loadable(lazy(() => import('views/users/index')));

// Pod Dashboard
const Pods = Loadable(lazy(() => import('views/Pods/Pods')));
const PodsDetails = Loadable(lazy(() => import('views/Pods/Poddetails')));
const PodSceneDetails = Loadable(lazy(() => import('views/Pods/PodSceneDetails')));
// const PodHistory = Loadable(lazy(() => import('views/Pods/Podhistory')));
// const Poddetails = Loadable(lazy(() => import('views/Pods/Poddetails')));

// ===========================|| MAIN ROUTING ||=========================== //
// Settings
const Settings = Loadable(lazy(() => import('views/settings')));
const Report = Loadable(lazy(() => import('views/report')));

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '*',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/games',
            element: <GameDashboard />
        },
        {
            path: '/games/details::address',
            element: <GameDetails />
        },
        {
            path: '/pods/details::address',
            element: <PodsDetails />
        },
        {
            path: '/games/scene/details::address',
            element: <Scenedetails />
        },
        {
            path: '/pods/scene/details::address',
            element: <PodSceneDetails />
        },
        {
            path: '/games/history',
            element: <GamesHistory />
        },
        {
            path: '/games/historydetails::gameID/user::userID',
            element: <HistoryDetails />
        },
        {
            path: '/settings',
            element: <Settings />
        },
        {
            path: '/report',
            element: <Report />
        },
        {
            path: '/users',
            element: <UsersIndex />
        },
        {
            path: '/pods',
            element: <Pods />
        }
        // {
        //     path: '/pods/Podhistory',
        //     element: <PodHistory />
        // }
        // {
        //     path: '/Poddetails',
        //     element: <Poddetails />
        // }
    ]
};

export default MainRoutes;
