/* eslint-disable jsx-a11y/accessible-emoji */
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import axios from 'axios';
import { APIURI } from 'config';
import React, { useState, useEffect } from 'react';
import { BallTriangle } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';

export default function HistoryDetails() {
    const { gameID, userID } = useParams();
    const [historyItems, setHistoryItems] = useState();
    const [historyItemsID, setHistoryItemsID] = useState([]);
    const [load, setLoad] = useState('');

    async function loadUserInputs() {
        setLoad(true);
        const webApiUrl = `${APIURI}user-inputs/view`;
        const tokenStr = localStorage.getItem('access_token');
        console.log(gameID, userID, 'gameiduserid');
        const data = {
            game_id: gameID,
            user_id: userID
        };
        await axios
            .post(webApiUrl, data, { headers: { Authorization: `Bearer ${tokenStr}` } })
            .then((res) => {
                console.log('res from api', res);
                setHistoryItems(res.data.message);
                // const arr = [];
                // // eslint-disable-next-line array-callback-return
                // res.data.message.map((fields) => {
                //     arr.push(fields.game_id);
                //     console.log(arr);
                // });
                // setHistoryItemsID(arr);
                setLoad(false);
            })
            .catch((err) => {
                setLoad(false);
                console.log(err);
            });
    }
    useEffect(() => {
        loadUserInputs();
    }, []);
    const TablesInputHistory = () => (
        <TableContainer className="history-table" component={Paper}>
            <Table sx={{ minWidth: '50vw' }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">
                            <h3>Sl.No</h3>
                        </TableCell>
                        <TableCell align="center">
                            <h3>Scenes</h3>
                        </TableCell>
                        <TableCell align="center">
                            <h3>Solutions</h3>
                        </TableCell>
                        <TableCell align="center">
                            <h3>Answered </h3>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {/* eslint-disable-next-line array-callback-return */}
                    {historyItems
                        ? historyItems.scene_details.map((item, index) => {
                              console.log('TABLE DATA ITEMS:', historyItems);
                              return (
                                  <TableRow key={index}>
                                      <TableCell align="center" component="th" scope="row">
                                          {index + 1}
                                      </TableCell>
                                      <TableCell align="center">
                                          <p>{item.scene_title}</p>
                                      </TableCell>
                                      <TableCell align="center">
                                          {item.right_solution.map((it, index) => (
                                              <div key={index}>
                                                  <p>{it}</p>
                                              </div>
                                          ))}
                                      </TableCell>
                                      <TableCell align="center">
                                          {item.user_input.map((op, index) =>
                                              op.status ? (
                                                  <p key={index}>
                                                      {op.input} <span role="img">✅</span>
                                                  </p>
                                              ) : (
                                                  <p key={index}>
                                                      {op.input} <span role="img">❌</span>
                                                  </p>
                                              )
                                          )}
                                      </TableCell>
                                  </TableRow>
                              );
                          })
                        : ''}
                    {console.log(historyItems, 'history itemss')}
                </TableBody>
            </Table>
        </TableContainer>
    );
    if (load) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20%' }}>
                <BallTriangle color="#000" height={80} width={80} />{' '}
            </div>
        );
    }
    return (
        <div>
            <h1 className="title">History Details</h1> <br />
            <TablesInputHistory />
        </div>
    );
}
